<template>
  <div class="container"></div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAccessTokenFromCookie } from "@/auth/utils";
export default {
  name: "VerifyEmail",
  data() {
    return {
      active: false,
    };
  },
  methods: {},
  created() {
    this.$store
      .dispatch("verifyEmail", this.$route.query)
      .then((response) => {
        const data_ = getAccessTokenFromCookie();
        data_[2].email_verified_at = true;
        const newData = {
          accessToken: data_[0],
          userAccess: data_[1],
          userData: data_[2],
        };
        const cookieValue = JSON.stringify(newData);
        var expires = new Date();
        expires.setMonth(expires.getMonth() + 1);
        expires = expires.toUTCString();
        document.cookie = `auth_data=${cookieValue}; expires=${expires}; path=/; Secure; SameSite=Strict;`;
        const alert = document.getElementById('activate-acc');
        if(alert) {
          alert.style.display = "none";
        }
        this.$router.push({ name: "dashboard" });
        const title = response.data.status;
        const icon =
          response.data.status === "Success"
            ? "CheckIcon"
            : "AlertTriangleIcon";
        const text = response.data.message;
        const variant = response.data.status.toLowerCase();
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (!data) {
      next("/login");
    }else {
      next(true);
    }
  },
};
</script>
